import React, { useState } from "react";
import {
  Modal,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListIcon from "@mui/icons-material/List"; // Ensure ListIcon is imported
import CloseIcon from "@mui/icons-material/Close";
import {
  handleAddExpenseSubmit,
  handleEditExpenseSubmit,
  handleListExpensesClick,
  handleDeleteExpense,
  fetchExpenseDetails,
} from "../utils/expenses_utils";

const ExpenseModal = ({ open, handleClose }) => {
  const initialFormData = {
    description: "",
    amount: "",
    date_spent: "",
    category: "",
    partialPayment: false, // New field for partial payment checkbox
    totalParts: 1, // Default to 1, but will be updated if it's a partial payment
  };

  const [expenseData, setExpenseData] = useState(initialFormData); // For the initial form
  const [editExpenseData, setEditExpenseData] = useState(initialFormData); // For the edit form
  const [showListModal, setShowListModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); // State for the Edit modal
  const [expensesList, setExpensesList] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState(null); // For holding selected expense for editing

  const handleChange = (e, setState) => {
    const { name, value, type, checked } = e.target;
    setState((prev) => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
  };

  const resetForm = () => {
    setExpenseData(initialFormData); // Reset form to initial values
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleAddExpenseSubmit(e, handleClose, expenseData.totalParts); // Include totalParts in submission
    resetForm();
  };

  const handleListExpenses = async (month, year) => {
    await handleListExpensesClick(month, year, setShowListModal, setExpensesList);
  };

  const handleDelete = async (expenseId) => {
    await handleDeleteExpense(expenseId);
    await handleListExpenses(); // Refresh list after deletion
  };

  const handleEdit = async (expenseId) => {
    const expenseDetails = await fetchExpenseDetails(expenseId);
    if (expenseDetails) {
      const formattedDate = expenseDetails.date_spent
        ? expenseDetails.date_spent.split("T")[0] // Format the date as YYYY-MM-DD
        : ""; // Handle undefined or missing date

      setSelectedExpense(expenseDetails); // Set the details of the selected expense
      setEditExpenseData({
        ...expenseDetails,
        date_spent: formattedDate, // Assign the formatted date to the state
      });
      setShowEditModal(true); // Open the edit modal
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    await handleEditExpenseSubmit(
      e,
      selectedExpense.id,
      () => setShowEditModal(false) // Close the edit modal
    );
    await handleListExpenses(); // Refresh list after editing
  };

  return (
    <>
      {/* Initial Submit Expense Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 35,
            width: 400,
          }}
        >
          {/* Close button at the top-right corner */}
          <Button
            onClick={() => {
              handleClose();
              resetForm(); // Reset form when the modal is closed via close button
            }}
            variant="outlined"
            color="secondary"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>

          <Button onClick={handleListExpenses} startIcon={<ListIcon />}>
            List
          </Button> {/* Add the List button here with ListIcon */}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={expenseData.description}
              onChange={(e) => handleChange(e, setExpenseData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Amount"
              name="amount"
              type="number"
              value={expenseData.amount}
              onChange={(e) => handleChange(e, setExpenseData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Date Spent"
              name="date_spent"
              type="date"
              value={expenseData.date_spent}
              onChange={(e) => handleChange(e, setExpenseData)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={expenseData.category}
                onChange={(e) => handleChange(e, setExpenseData)}
              >
                <MenuItem value="1">Groceries</MenuItem>
                <MenuItem value="2">Rent</MenuItem>
                {/* Add other categories */}
              </Select>
            </FormControl>

            {/* Checkbox for Partial Payment */}
            <FormControlLabel
              control={
                <Checkbox
                  name="partialPayment"
                  checked={expenseData.partialPayment}
                  onChange={(e) => handleChange(e, setExpenseData)}
                />
              }
              label="Partial Payment"
            />

            {/* Dropdown for Number of Payments if Partial Payment is checked */}
            {expenseData.partialPayment && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Number of Payments</InputLabel>
                <Select
                  name="totalParts"
                  value={expenseData.totalParts}
                  onChange={(e) => handleChange(e, setExpenseData)}
                >
                  {Array.from({ length: 36 }, (_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <Button type="submit" color="primary" variant="contained" fullWidth>
              Save
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Modal to display the list of expenses */}
      <Modal open={showListModal} onClose={() => setShowListModal(false)}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            width: 600,
          }}
        >
          <Button
            onClick={() => setShowListModal(false)}
            variant="outlined"
            color="secondary"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>

          <h2 style={{ textAlign: "center" }}>Expenses List</h2>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Date Spent</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Actions</TableCell> {/* Single actions column */}
              </TableRow>
            </TableHead>
            <TableBody>
              {expensesList.map((expense, index) => (
                <TableRow key={index}>
                  <TableCell>{expense.id}</TableCell>
                  <TableCell>{expense.description}</TableCell>
                  <TableCell>{expense.amount}</TableCell>
                  <TableCell>{expense.date_spent}</TableCell>
                  <TableCell>{expense.category}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => handleEdit(expense.id)}
                      color="primary"
                      startIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDelete(expense.id)}
                      color="secondary"
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Modal>

      {/* Modal for editing expense */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: 20,
            width: 400,
          }}
        >
          {/* Close button at the top-right corner */}
          <Button
            onClick={() => setShowEditModal(false)}
            variant="outlined"
            color="secondary"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>

          <form onSubmit={handleEditSubmit}>
            <h2>Edit Expense</h2>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={editExpenseData.description}
              onChange={(e) => handleChange(e, setEditExpenseData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Amount"
              name="amount"
              type="number"
              value={editExpenseData.amount}
              onChange={(e) => handleChange(e, setEditExpenseData)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Date Spent"
              name="date_spent"
              type="date"
              value={editExpenseData.date_spent}
              onChange={(e) => handleChange(e, setEditExpenseData)}
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={editExpenseData.category}
                onChange={(e) => handleChange(e, setEditExpenseData)}
              >
                <MenuItem value="1">Groceries</MenuItem>
                <MenuItem value="2">Rent</MenuItem>
                {/* Add other categories */}
              </Select>
            </FormControl>
            <Button type="submit" color="primary" variant="contained" fullWidth>
              Save Changes
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default ExpenseModal;

import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { Box, Card, CardContent, Typography, Divider } from "@mui/material";

const ExpedingsGraph = () => {
  const chartRef = useRef(null); // Create a ref for the canvas
  const chartInstance = useRef(null); // Hold the chart instance

  useEffect(() => {
    // Ensure the chart is not already initialized
    if (chartInstance.current) {
      chartInstance.current.destroy(); // Destroy any existing chart instance
    }

    const ctx = chartRef.current.getContext("2d"); // Get the context from the ref
    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "Groceries",
          "Utilities",
          "Entertainment",
          "Transportation",
          "Healthcare",
          "Dining Out",
          "Rent/Mortgage",
          "Savings & Investments",
          "Insurance",
          "Education",
          "Clothing & Accessories",
          "Travel",
          "Personal Care",
          "Gifts & Donations",
          "Technology",
          "Pet Care",
          "Household Supplies",
          "Home Improvement",
        ],
        datasets: [
          {
            data: [
              250, 150, 120, 350, 200, 180, 800, 500, 100, 200, 150, 300, 100,
              80, 220, 90, 50, 200,
            ],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(201, 203, 207, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(201, 203, 207, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(255, 159, 64, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(201, 203, 207, 1)",
              "rgba(255, 205, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(201, 203, 207, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.8,
        plugins: {
          legend: {
            display: false, // Add this line to hide the legend
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label += `R$ ${context.parsed.y.toFixed(2)}`; // Format numbers with currency
                }
                return label;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              // Format the ticks on the y-axis
              callback: function (value, index, ticks) {
                return "R$ " + value.toFixed(2);
              },
            },
          },
        },
      },
    });

    // Cleanup function to destroy the chart instance
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount and then on unmount

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
      <Card sx={{ mt: 2, textAlign: "center" }}>
        <CardContent>
          <Typography variant="h6" component="div">
            Spending Breakdown by Category
          </Typography>
          <Divider sx={{ my: 1 }} />
          <canvas ref={chartRef} width="100" height="100"></canvas>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ExpedingsGraph;

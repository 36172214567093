export const handleAddExpenseSubmit = async (
  event,
  setShowAddExpenseModal,
  totalParts = 1,
) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const token = localStorage.getItem("token");
  if (totalParts === 1) {
    try {
      const response = await fetch("https://money-couple.com/api/expense/", {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: formData.get("description"),
          amount: formData.get("amount"),
          date_spent: formData.get("date_spent"),
          category: formData.get("category"),
        }),
      });

      if (response.ok) {
        console.log("Expense added successfully");
        setShowAddExpenseModal(false);
      } else {
        const errorData = await response.json();
        console.error("Failed to add expense", errorData);
      }
    } catch (error) {
      console.error("Error adding expense:", error);
    }
  } else {
    try {
      const initialDate = new Date(formData.get("date_spent"));
      for (let i = 0; i < totalParts; i++) {
        const paymentDate = new Date(
          initialDate.getFullYear(),
          initialDate.getMonth() + i,
          initialDate.getDate(),
        );
        const formattedDate = paymentDate.toISOString().split("T")[0];
        const response = await fetch("https://money-couple.com/api/expense/", {
          method: "POST",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: formData.get("description"),
            amount: formData.get("amount") / totalParts,
            date_spent: formattedDate,
            category: formData.get("category"),
          }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          console.error("Failed to add expense part", errorData);
          break;
        }
      }
      setShowAddExpenseModal(false);
      console.log("All expense parts added successfully");
    } catch (error) {
      console.error("Error adding expense:", error);
    }
  }
};

export const handleListExpensesClick = async (
  month,
  year,
  setShowListExpensesModal,
  setExpensesList,
) => {
  const token = localStorage.getItem("token");
  const url = `https://money-couple.com/api/expense/list/?month=${month}&year=${year}`;

  setShowListExpensesModal(true);
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setExpensesList(data);
    } else {
      console.error("Failed to fetch expenses");
      setShowListExpensesModal(false);
    }
  } catch (error) {
    console.error("Error fetching expenses:", error);
    setShowListExpensesModal(false);
  }
};

export const handleDeleteExpense = async (expenseId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `https://money-couple.com/api/expense/delete/${expenseId}/`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );

    if (response.ok) {
      console.log("Expense deleted successfully");
    } else {
      console.error("Failed to delete expense");
    }
  } catch (error) {
    console.error("Error deleting expense:", error);
  }
};

export const fetchExpenseDetails = async (expenseId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `https://money-couple.com/api/expense/edit/${expenseId}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to fetch expense details");
      return null;
    }
  } catch (error) {
    console.error("Error fetching expense details:", error);
    return null;
  }
};

export const handleEditExpenseSubmit = async (
  event,
  expenseId,
  setShowEditExpenseModal,
) => {
  event.preventDefault();
  const { description, amount, date_spent, category } = event.target.elements;
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(
      `https://money-couple.com/api/expense/edit/${expenseId}/`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          description: description.value,
          amount: amount.value,
          date_spent: date_spent.value,
          category: category.value,
        }),
      },
    );

    if (response.ok) {
      console.log("Expense edited successfully");
      setShowEditExpenseModal(false);
    } else {
      console.error("Failed to edit expense");
    }
  } catch (error) {
    console.error("Error editing expense:", error);
  }
};

// Updated fetchTopCategories function
export const fetchTopCategories = async (selectedDate, setTopCategories) => {
  const token = localStorage.getItem("token");
  const adjustedMonth = selectedDate.month + 1;
  try {
    const response = await fetch(
      `https://money-couple.com/api/expenses/category/${selectedDate.year}/${adjustedMonth}/`,
      {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTopCategories(data);
    } else {
      console.error("Failed to fetch top categories data.");
    }
  } catch (error) {
    console.error("Error fetching top categories data:", error);
  }
};

// Updated fetchTotalExpenses function
export const fetchTotalExpenses = async (selectedDate, setTotalExpenses) => {
  const token = localStorage.getItem("token");
  const adjustedMonth = selectedDate.month + 1;
  const response = await fetch(
    `https://money-couple.com/api/total-expenses/${selectedDate.year}/${adjustedMonth}/`,
    {
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    },
  );
  const data = await response.json();
  setTotalExpenses(data.total_expense);
};

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";

import {
  Button,
  Box,
  useTheme,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import SettingsIcon from "@mui/icons-material/Settings";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import HelpModal from "./modals/HelpModal";
import WorldClockModal from "./modals/WorldClockModal";
import IncomeModal from "./modals/IncomeModal";
import ExpenseModal from "./modals/ExpenseModal";
import GoalsModal from "./modals/GoalsModal";
import CategoryModal from "./modals/CategoryModal";
import ExpedingsGraph from "./utils/Graphs";
import IncomeExpensesCard from "./utils/IncomeExpensesCard";
import RecentActivityCard from "./utils/RecentActivityCard";
import UpcomingBillsCard from "./utils/UpcomingBillsCard";
import GoalsProgressCard from "./utils/GoalsProgressCard";
import FinancialOverviewCard from "./utils/FinancialOverviewCard";
import handleAddGoalSubmit from "./utils/goals_utils";
import { Card, CardContent, Typography, Divider } from "@mui/material";
function Dashboard({ toggleTheme }) {
  const theme = useTheme();
  const [showHelpModal, setShowHelpModal] = React.useState(false);
  const [showIncomeModal, setShowIncomeModal] = useState(false);
  const [incomeDetails, setIncomeDetails] = useState(null);
  const [showExpenseModal, setShowExpenseModal] = useState(false);
  const [expenseDetails, setExpenseDetails] = useState(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showGoalsModal, setShowGoalsModal] = useState(false);
  const [goalDetails, setGoalDetails] = useState(null);

  const token = localStorage.getItem("token");

  const handleLogoutClick = () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  };

  const handleProfileClick = () => {
    console.log("Profile icon clicked");
    // Add functionality for profile click, like opening a profile modal
  };

  const handleSettingsClick = () => {
    console.log("Settings icon clicked");
    // Add functionality for settings click, like opening a settings dialog
  };

  const handleOpenCategoryModal = () => setShowCategoryModal(true);
  const handleCloseCategoryModal = () => setShowCategoryModal(false);

  const handleOpenIncomeModal = (income = null) => {
    setIncomeDetails(income);
    setShowIncomeModal(true);
  };

  const handleOpenExpenseModal = (income = null) => {
    setExpenseDetails(income);
    setShowExpenseModal(true);
  };
  const handleOpenGoalsModal = (details = null) => {
    setGoalDetails(details);
    setShowGoalsModal(true);
  };

  const handleCloseGoalsModal = () => {
    setShowGoalsModal(false);
    setGoalDetails(null);
  };

  const handleSaveGoal = async (goalData) => {
    const closeModal = () => {
      setShowGoalsModal(false);
      setGoalDetails(null);
    };

    try {
      await handleAddGoalSubmit(goalData, closeModal);
    } catch (error) {
      console.error("Error saving goal:", error);
    }
  };

  return (
    <Box className="container">
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: 1,
          backgroundColor: theme.palette.background.default,
          width: "auto",
          height: "auto",
          textAlign: "center",
        }}
      >
        <WorldClockModal />
      </Box>
      <Box sx={{ textAlign: "center", p: 1 }}>
        <Button
          onClick={handleLogoutClick}
          startIcon={<LogoutIcon />}
          size="large"
          sx={{ fontSize: "1.1rem" }}
        >
          Logout
        </Button>
        <Button
          onClick={() => console.log("Profile clicked")}
          startIcon={<AccountCircleIcon />}
          size="large"
          sx={{ fontSize: "1.1rem" }}
        >
          Profile
        </Button>
        <Button
          onClick={() => setShowHelpModal(true)}
          startIcon={<HelpIcon />}
          size="large"
          sx={{ fontSize: "1.1rem" }}
        >
          Help
        </Button>
        <HelpModal
          open={showHelpModal}
          onClose={() => setShowHelpModal(false)}
        />
        <Button
          onClick={() => console.log("Settings clicked")}
          startIcon={<SettingsIcon />}
          size="large"
          sx={{ fontSize: "1.1rem" }}
        >
          Settings
        </Button>
        <Button
          onClick={toggleTheme}
          startIcon={<Brightness4Icon />}
          size="large"
          sx={{ fontSize: "1.1rem" }}
        >
          Toggle Theme
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: "40%",
          transform: "translateY(-50%)",
          width: 270,
          padding: 1,
          backgroundColor: theme.palette.background.default,
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => handleOpenIncomeModal()}
          size="large"
          sx={{ fontSize: "1.3rem" }}
        >
          Incomes
        </Button>
        <Button
          onClick={() => handleOpenExpenseModal()}
          size="large"
          sx={{ fontSize: "1.3rem" }}
        >
          Expenses
        </Button>
        <Button size="large" sx={{ my: 1, fontSize: "1.3rem" }}>
          Investments
        </Button>
        <Button
          onClick={() => handleOpenGoalsModal()}
          size="large"
          sx={{ fontSize: "1.3rem" }}
        >
          Goals
        </Button>
        <Button size="large" sx={{ my: 1, fontSize: "1.3rem" }}>
          Budgets
        </Button>
        <Button
          onClick={() => handleOpenCategoryModal()}
          size="large"
          sx={{ fontSize: "1.3rem" }}
        >
          categories
        </Button>
        <Button size="large" sx={{ my: 1, fontSize: "1.3rem" }}>
          Reports
        </Button>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          padding: 3,
          marginLeft: "280px",
          marginTop: "75px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Top Section: Summary Widgets */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            marginBottom: 4,
          }}
        >
          <IncomeExpensesCard />
          <RecentActivityCard />
          <UpcomingBillsCard />
        </Box>
        {/* Middle Section: Charts and Graphs */}
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
          <ExpedingsGraph />
        </Box>
        {/* Bottom Section: Goals Progress and Notifications */}
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <GoalsProgressCard />
          <FinancialOverviewCard />
        </Box>
      </Box>
      <IncomeModal
        open={showIncomeModal}
        handleClose={() => setShowIncomeModal(false)}
        incomeDetails={incomeDetails}
        handleSave={(data) => console.log("Income Data", data)}
      />
      <ExpenseModal
        open={showExpenseModal}
        handleClose={() => setShowExpenseModal(false)}
        expenseDetails={expenseDetails}
        handleSave={(data) => console.log("Income Data", data)}
      />
      <GoalsModal
        open={showGoalsModal}
        handleClose={handleCloseGoalsModal}
        goalDetails={goalDetails}
        handleSave={handleSaveGoal}
      />
    </Box>
  );
}

export default Dashboard;


import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  LinearProgress,
  Box,
} from "@mui/material";

const GoalsProgressCard = () => {
  // Example goals data
  const goals = [
    { id: 1, name: "Savings for Vacation", target: 3000, current: 1500 },
    { id: 2, name: "Emergency Fund", target: 5000, current: 2500 },
    { id: 3, name: "New Car Fund", target: 20000, current: 5000 },
  ];

  // Calculate progress percentage
  const calculateProgress = (current, target) => (current / target) * 100;

  return (
    <Card
      sx={{
        flex: 1,
        margin: 1,
        textAlign: "center",
        p: 2,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Goals Progress
        </Typography>
        <Divider sx={{ my: 2 }} />
        {goals.map((goal) => (
          <Box key={goal.id} sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {goal.name}: {goal.current} / {goal.target}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculateProgress(goal.current, goal.target)}
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default GoalsProgressCard;

import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const UpcomingBillsCard = () => {
  // Example data for upcoming bills
  const bills = [
    { id: 1, desc: "Electricity", amount: "$200", due: "2024-09-15" },
    { id: 2, desc: "Internet", amount: "$80", due: "2024-09-10" },
    { id: 3, desc: "Water", amount: "$40", due: "2024-09-20" },
    { id: 4, desc: "Netflix", amount: "$15", due: "2024-09-05" },
    { id: 5, desc: "Rent", amount: "$1500", due: "2024-09-01" },
  ];

  return (
    <Card
      sx={{
        flex: 1,
        margin: 1,
        textAlign: "center",
        p: 2,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Upcoming Bills
        </Typography>
        <Divider sx={{ my: 2 }} />
        <List sx={{ width: "100%" }}>
          {bills.map((bill) => (
            <ListItem
              key={bill.id}
              sx={{ justifyContent: "center", textAlign: "center" }}
            >
              <ListItemText
                primary={`${bill.desc}: ${bill.amount}`}
                secondary={`Due: ${bill.due}`}
                primaryTypographyProps={{ textAlign: "center" }}
                secondaryTypographyProps={{ textAlign: "center" }}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default UpcomingBillsCard;
